/* eslint-disable global-require */
import React from 'react';
import Head from 'next/head';
import type { NextPage, GetServerSideProps } from 'next';

import { Page } from '@/components/layout';
import { Home } from '@/components/pages';
import generateMetaTags from '@/utils/meta';
import type { HomeResponse } from '@/api/types';
import { getStartPage } from './api/start';

type Props = {
  data: HomeResponse;
};

const Index: NextPage<Props> = ({ data }) => {
  return (
    <Page backgroundColor="brand.blue.faded">
      <Head>
        {generateMetaTags({
          title: 'Got a NSW traffic offence problem?',
          description:
            'This tool is designed by lawyers to help you find the best way to deal with your traffic offence problem.',
          image: require('@/images/person-driving-car.jpg'),
        })}
      </Head>
      <Home
        page={data.page}
        startingPoints={data.startingPoints}
        resources={data.resources}
      />
    </Page>
  );
};

// TODO: Change to use Incremental Static Regeneration™ when feature is stable
export const getServerSideProps: GetServerSideProps = async () => {
  const data = await getStartPage();
  return {
    props: { data },
  };
};

export default Index;
